import axios from "axios";
import CONFIG from '../config.json'

const api = axios.create({
  baseURL: CONFIG.backendAPIUri + "/api/v1",
  headers: {
    Authorization: `Bearer ${localStorage.token}`
  }
});

export default api;
